.online-prime {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .image-container-online {
    position: relative;
    width: 100%;
    height: 400px; /* Fixed height */
    overflow: hidden; /* Ensures nothing extends beyond the container size */
  }
  
  .image-container-online::before {
    display: none; /* Hide any pseudo-elements that might be adding unwanted backgrounds */
  }

  .overlay2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 0;
}
  
  .online-image {
    position: absolute;
    top: 180%; /* Push the top of the image to align at the bottom of the container */
    left: 0;
    width: 100%;
    height: auto; /* Allow natural height to maintain aspect ratio */
    transform: translateY(-80%); /* Shift the image back up by its own height */
    object-fit: cover; /* Cover the area without distorting the aspect ratio */
  }
  
  .overlay-text-online {
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Align center perfectly */
    color: white; /* white color for visibility */
    text-align: center;
    width: 100%;
    font-size: 40px;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .online-content {
    width: 60%;
    padding-top: 60px;
    padding-bottom: 75px;
    text-align: center;
    color: #0A1322;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .online-content h1, .online-content h2 {
    color: #0e1726;
    margin-bottom: 40px;
    font-family: "Akshar", sans-serif;
font-weight: 400;
font-size: 40px;
  }
  
  .online-content p {
    font-size: 22px;
    color: #0A1322;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
 
  @media (max-width: 1550px) {
    .online-content {
      height: 120vh;
    }
    
  }
  @media (max-width: 1024px) {
    .online-image {
      top: 0; /* Align the top of the image to the container */
      transform: translateY(0); /* No translation, so the image fits perfectly at the top */
      height: 100%; /* Adjust height to ensure the image covers the full container */
      transform: scale(1.5);
    }
  }
  
  @media (max-width: 992px) {
    .online-image {
      top: 0; /* Align the top of the image to the container */
      transform: translateY(0); /* No translation, so the image fits perfectly at the top */
      height: 100%; /* Adjust height to ensure the image covers the full container */
    }
  }
  
  @media (max-width: 768px) {
    .overlay-text-online h1{
      font-size: 70px;
    }
  
  
  }
  
  @media (max-width: 480px) {
    .overlay-text-online h1{
      font-size: 36px;
    }
  
    .image-container-online {
      height: 170px;
  
    }
    .online-image {
      transform: scale(1.5);
      height: 200px;
      
  }
  
  .online-content h2{
    font-size: 30px;
    margin-bottom: 20px;
  }
  
  .online-content {
    width: 80%;
    height: 120vh;
  }
  .online-content p{
    font-size: 16px;
    
   
  }
  }
  @media (max-width: 360px) {
    .overlay-text-online h1{
      font-size: 36px;
    }
  }
  
  