.subjects-section {
    text-align: center;
}

.subject-image-container {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
}

.subject-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Adding a dark overlay */
.overlay-subject {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
}

.overlay-text-subject {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    width: 100%;
    z-index: 2;
    font-size: 40px;
    font-weight: bold;
}

.subject-text1 {
    width: 60%;
    margin: 0 auto; /* Center the element horizontally */
    text-align: center; /* Center the text inside the element */
    padding-top: 70px;
    padding-bottom: 100px;
    height: 60vh;

    /* Flexbox settings for vertical centering */
    display: flex;
    flex-direction: column;
    justify-content: center;
}




/* .subject-text1 h2{
    font-size: 31px;
    color: #0e1726;
    margin-bottom: 40px;
}

.subject-text1 p{
    font-size: 20px;
    color: #0e1726;
    margin-bottom: 10px;
} */

.subject-text1 h2 {
    margin-top: 0px;
    font-family: "Akshar", sans-serif;
font-weight: 400;
font-size: 40px;
    color: #0e1726;
    margin-bottom: 40px;
}

.subject-text1 p {
    font-size: 22px;
    color: #0e1726;
    margin-bottom: 20px;
    font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.5;
}

.subjects-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    padding-top: 150px;
    padding-bottom: 150px;
    background-image: url('../../assets/pattern2.png'); 
    background-size: cover;
    background-position: center; 
    height: 70vh;
    /* background-color: #0e1726; */
}

.subjects-icons::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.56); /* White overlay with 50% opacity */
    z-index: 1;
    pointer-events: none; /* Allow clicks to pass through */
}

.subject-icon {
    position: relative;
    z-index: 2; /* Ensure icons are above the overlay */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.middle-line {
    z-index: 2;
}

/* 
 #c6e2f9
 #dceefc

 #b6d9f5 */
 

.subject-icon {
    text-align: center;
    text-decoration: none;
    color: #0e1726;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px; /* Adjust width as needed */
    margin: 20px; /* Adjust margin as needed */
}

.subject-icon img {
    width: 100px; /* Adjust the size of the icon as needed */
    height: 100px; /* Adjust the size of the icon as needed */
    object-fit: contain; /* Ensure the image covers the entire area without distortion */
    transition: transform 0.3s ease; /* Smooth transition */

}

.subject-icon p {
    margin-top: 10px;
    font-size: 23px;
    width: 100%;
    font-family: "Akshar", sans-serif;
    font-weight: 400;
    color:#0e1726;
}

/* Zigzag adjustments */
.subject-icon:nth-child(even) {
    transform: translateY(-125px); /* Move up odd icons */
   
}

.subject-icon:nth-child(odd) {
    transform: translateY(125px); /* Move down even icons */
    
}

.middle-line {
    width: 52%;
    height: 3px;
    background-color: #0e1726;
    position: absolute;
    top: 50%; /* Center the line */
    transform: translateY(-5px);
    
    
}

.subject-icon img:hover {
    transform: scale(1.05); /* Scale up by 10% */
    background-color: rgba(243, 157, 18, 0.8); /* Light blue background */
    border-radius: 50%;
}

@media (max-width: 1550px) {
.subject-text1 {
    height: 70vh;
}
}

@media (max-width: 1024px) {
    .subject-text1 {
        width: 80%;
    }

    .subjects-icons {
        display: grid;
        grid-template-columns: repeat(4, 1fr); /* Creates 4 columns */
        gap: 10px; /* Adjusts spacing between grid items */
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .subject-icon {
        width: auto; /* Adjusts width to fit grid layout */
        margin: 10px; /* Reduces margin for tighter spacing */
    }

    .subject-icon:nth-child(even) {
        transform: translateY(0px); /* Move up odd icons */
       
    }
    
    .subject-icon:nth-child(odd) {
        transform: translateY(0px); /* Move down even icons */
        
    }

    .middle-line {
        display: none;
    }
}

@media (max-width: 768px) {
    .overlay-text-subject h1{
      font-size: 70px;
    }
    .subject-text1 {
        width: 90%;
      }


      .subjects-icons {
        display: grid;
        grid-template-columns: repeat(4, 1fr); /* Creates 4 columns */
        gap: 10px; /* Adjusts spacing between grid items */
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .subject-icon {
        width: auto; /* Adjusts width to fit grid layout */
        margin: 10px; /* Reduces margin for tighter spacing */
    }

    .subject-icon:nth-child(even) {
        transform: translateY(0px); /* Move up odd icons */
       
    }
    
    .subject-icon:nth-child(odd) {
        transform: translateY(0px); /* Move down even icons */
        
    }

    .middle-line {
        display: none;
    }
}


@media (max-width: 480px) {
 

    .subject-image-container {
        height: 170px;
        margin-bottom: 0px;
    }

    .overlay-text-subject h1{
        font-size: 36px;
    }

    .subject-text1 {
        width: 90%;
        padding-bottom: 40px;
        height: 60vh;
    }

    .subject-text1 h2 {
        font-size: 30px;
        margin-bottom: 20px;
        margin-top: 0px;
    }
      
    

    .subject-text1 p {
        font-size: 15px;
        margin-bottom: 0px;
       
    }

    .subjects-icons {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Creates 4 columns */
        gap: 10px; /* Adjusts spacing between grid items */
        margin-top: 0px;
        padding: 30px 35px;
        height: 80vh;
    }

    .subject-icon {
        width: auto; /* Adjusts width to fit grid layout */
        margin: 10px; /* Reduces margin for tighter spacing */
    }

    .subject-icon img {
        width: 75px; /* Adjust the size of the icon as needed */
        height: 75px;
    }

    .subject-icon p {
        font-size: 16px;
    }

    .subject-icon:nth-child(even) {
        transform: translateY(0px); /* Move up odd icons */
       
    }
    
    .subject-icon:nth-child(odd) {
        transform: translateY(0px); /* Move down even icons */
        
    }

    .middle-line {
        display: none;
    }
}

@media (max-width: 360px) {
    .overlay-text-subject h1{
      font-size: 36px;
    }
  }