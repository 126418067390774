.pricing-section {
    background: #0e1726;
    color: white;
    text-align: center;
    padding: 60px 20px;
}

.pricing-section h2 {
    
    font-weight: bold;
    margin-bottom: 10px;
    font-family: "Akshar", sans-serif;
font-weight: 400;
font-size: 45px;
}

.pricing-section p {
    font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  font-size: 20px;
    margin-bottom: 20px;
}

.pricing-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 70px;
    margin-bottom: 70px;
    padding: 0 125px; 
}

.pricing-card {
    background: white;
    color: #0e1726;
    padding: 50px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
}

.pricing-card a{
text-decoration: none;
}

.pricing-card h3 {
    font-size: 25px;
    margin-bottom: 10px;
    font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 1.5;
}

.pricing-card .subheading {
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 30px;
    
}

.pricing-card .price {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 30px;
}

.pricing-card .per-hour {
    font-size: 16px;
    margin-bottom: 20px;
}

.pricing-button {
    background: #0e1726;
    color: white;
    border: none;
    padding: 15px 20px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
    font-family: "Roboto", sans-serif;
  font-weight: 300;
  text-decoration: none;
}

.pricing-button:hover {
    background: #F39C12 ;
    text-decoration: none;
}

.pricing-info {
    max-width: 1000px;
    margin: 0 auto;
    text-align: left;
    font-size: 16px;
}

.pricing-info p {
    margin-bottom: 25px; 
    font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.5;
}

@media (max-width: 1550px) {
.pricing-options{
    padding: 0;
}
}

@media (max-width: 992px) {
    .pricing-options {
        grid-template-columns: 1fr; /* Stack cards vertically */
        padding: 0 20px; /* Reduce padding to allow cards to take full width */
    }

    .pricing-section {
        padding: 40px 15px; /* Adjust padding for slightly smaller screens */
    }

    .pricing-card {
        margin: 0; /* Remove specific margins for tighter layout */
        padding: 40px 15px; /* Adjust padding inside the cards */
    }

    .pricing-info {
        text-align: center; /* Center align text for better readability */
        padding: 0 20px; /* Adjust padding */
    }
}

/* Adjustments for max-width: 768px */
@media (max-width: 768px) {
    .pricing-options {
        padding: 0 15px; /* Further reduce padding for smaller screens */
    }

    .pricing-card {
        padding: 40px 10px; /* Adjust card padding */
    }

    .pricing-section h2 {
        font-size: 28px;
    }

    .pricing-section p {
        font-size: 16px;
    }

    .pricing-card h3 {
        font-size: 20px;
    }

    .pricing-card .price {
        font-size: 36px;
    }

    .pricing-card .per-hour {
        font-size: 14px;
    }

    .pricing-button {
        font-size: 16px;
        padding: 10px 20px;
    }

    .pricing-info {
        font-size: 14px;
    }

    .pricing-info p {
        margin-bottom: 15px; /* Adjusted for mobile view */
    }
}

/* Adjustments for max-width: 480px */
@media (max-width: 480px) {
    .pricing-options {
        padding: 0 10px; /* Reduce padding further */
        gap: 40px; /* Slightly reduce gap for better fit */
    }

    .pricing-card {
        width: 100%; /* Ensure full width minus some padding */
        margin: 0 auto; /* Center the card */
        padding: 40px 10px; /* Adjust padding */
    }

    .pricing-section h2 {
        font-size: 30px; /* Adjust font size */
        margin-top: 40px;
        font-weight: 600;
    }

    .pricing-info p {
        font-size: 16px;
        margin-bottom: 25px;
    }

    .pricing-card h3 {
        font-size: 25px;
    }

    .pricing-card .subheading {
        margin-top: 10px;
    }

    .pricing-card .price {
        font-size: 32px;
        margin-top: 20px;
    }

    .pricing-button {
        font-size: 14px;
        padding: 8px 16px;
    }
}

/* Adjustments for max-width: 360px */
@media (max-width: 360px) {
    .pricing-section h2 {
        font-size: 24px;
    }

    .pricing-card {
        padding: 30px 10px;
    }

    .pricing-card .price,
    .pricing-card .per-hour,
    .pricing-info p {
        font-size: 14px;
    }

    .pricing-info {
        padding: 0 10px;
    }
}
