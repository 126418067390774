.services-overview {
    display: flex;
    justify-content: center; 
    flex-wrap: wrap;
    padding: 20px 0;
    background-color: #F7F7F7;
  }
  
  .service {
    flex: 1 1 30%; 
    max-width: 35%;
    text-align: center;
    padding: 20px;
    margin: 10px;
  }
  
  .service img {
    width: 100%;
    max-height: auto;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px; 
  }
  
  .service h3 {
    color: #0A1322;
    font-size: 35px;
    margin-bottom: 20px;
    font-family: "Akshar";
    font-weight: 400;
    margin-top: 20px;
  }
  
  .service p {
    font-size: 20px;
    color: #0c1422;
    margin-bottom: 20px;
    font-family: "Roboto", sans-serif;
  font-weight: 300;
  max-width: 90%;
  margin: 0 auto 40px auto;
  line-height: 1.3;
   
  }
  
  @media (max-width: 768px) {
    .service {
      flex-basis: 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .service {
      flex-basis: 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }

    .service h3 {
      color: #0A1322;
      font-size: 25px;
      margin-bottom: 20px;
      font-family: "Akshar";
      font-weight: 400;
      margin-top: 20px;
    }
    
    .service p {
      font-size: 15px;
      color: #0c1422;
      margin-bottom: 20px;
      width: 100%
     
    }
  }