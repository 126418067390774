.mathematics-overview-container {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.mathematics-image-container {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.mathematics-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Adding a dark overlay */
.overlay-mathematics {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.overlay-text-mathematics {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  width: 100%;
  z-index: 2;
  font-size: 40px;
  font-weight: bold;
}

.mathematics-book {
  position: relative;
  background-color: #0e1726;
  padding: 80px 0;
  width: 100%;
  margin: 0 auto;
  color: white;
  text-align: center;
  overflow: hidden;
}

.mathematics-p {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
   /* Adds margin at the bottom */
}

.mathematics-book::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #c8d4e6;
  clip-path: polygon(20% 0, 100% 0, 100% 55%, 80% 100%, 0 100%, 0 45%);
  z-index: 1;
}

.mathematics-book-content {
  position: relative;
  z-index: 2;
  width: 80%;
  margin: 0 auto;
  color: #0e1726;
  height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mathematics-book-content h2 {
  font-family: "Akshar", sans-serif;
  font-weight: 400;
  font-size: 40px;
      margin-bottom: 40px;
}

.mathematics-book-content p {
  font-size: 22px;
    margin-bottom: 40px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: 1.5;
}

.mathematics-book-content button {
  background-color: #0e1726;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 10%;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;  
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 0 auto 0 auto;
}

.mathematics-book-content button:hover {
  background-color: #F39C12;
}

.mathematics-statement {
  background-color: white;
  width: 70%;
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 20px auto 30px auto;
  position: relative;
  height: 40vh;
  color:#0e1726;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mathematics-final {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 40px;
}

.mathematics-name {
  font-size: 20px;
  word-spacing: 2px;
}

.mathematics-overview-container .mathematics-title {
  font-size: 40px;
  margin: 20px 0;
  color: white;
  font-family: "Akshar", sans-serif;
  font-weight: 400;
}

.mathematics-overview-container .mathematics-subtitle {
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  margin: 10px 0 40px;
  color: white;
}

.mathematics-main-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  max-width: 80%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.mathematics-main-tab-button {
  flex: 1;
  padding: 10px 0;
  border: solid white 1px;
  cursor: pointer;
  font-size: 18px;
  background-color: transparent;
  color: white;
  transition: background-color 0.3s, color 0.3s;
  max-width: 222px; /* Adjust as needed */
  margin: 0 5px;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
    font-weight: 400;
}

.mathematics-main-tab-button:hover {
  background-color: #dbefff; /* Change this to your desired hover color */
  color: #0e1726; /* Change the text color on hover */
  border: none;
}

.mathematics-main-tab-button.active {
  background-color: #1c377e;
  color: white;
  border: solid 1px #1c377e;
}

.mathematics-sub-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  max-width: 80%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.mathematics-tab-button {
  flex: 1;
  padding: 10px 0;
  border: solid white 1px;
  cursor: pointer;
  font-size: 18px;
  background-color: transparent;
  color: white;
  transition: background-color 0.3s, color 0.3s;
  max-width: 450px; /* Adjust as needed */
  margin: 0 10px;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.mathematics-tab-button:hover {
  background-color: #dbefff; /* Change this to your desired hover color */
  color: #0e1726; /* Change the text color on hover */
  
}

.mathematics-tab-button.active {
  background-color: #F39C12;
  color: white;
  border: solid 1px #F39C12;
}

.mathematics-tab-content {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  height: 0;
  overflow: hidden;
}

.mathematics-tab-content.active {
  opacity: 1;
  height: auto;
  transition: opacity 0.3s ease-in-out, height 0s;
}

.mathematics-timeline, .mathematics-timeline-12 {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
}

.mathematics-timeline-item {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
  width: 100%;
  position: relative;
}

.mathematics-timeline-item.left {
  justify-content: flex-start;
}

.mathematics-timeline-content {
  background-color: #3e4f75;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  margin-top: 0;
  color: #ffffff;
}

.mathematics-timeline-content p {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
    line-height: 1.5;
}

.mathematics-timeline-content h3 {
  color: #F39C12;
  font-size: 22px;
  margin-bottom: 10px;
  margin-top: 0;
  font-family: "Roboto", sans-serif;
}

.mathematics-timeline-item.left .mathematics-timeline-content::after {
  content: '';
  position: absolute;
  top: 25px;
  right: -15px;
  width: 15px;
  height: 25px;
  background-color: #3e4f75;
  clip-path: polygon(0 0, 0 100%, 100% 50%);
  transform: translateY(-50%);
}

.mathematics-timeline-item.right .mathematics-timeline-content::after {
  content: '';
  position: absolute;
  top: 25px;
  left: -14px;
  width: 15px;
  height: 25px;
  background-color: #3e4f75;
  clip-path: polygon(100% 0, 100% 100%, 0 50%);
  transform: translateY(-50%);
}

.mathematics-timeline-icon {
  padding: 10px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 20px;
  height: 20px;
  background-color: #c6e2f9;
  color: #0e1726;
  font-weight: 700;
  border: 3px solid #0e1726;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 2;
  top: 20px;
}

.mathematics-timeline::before, .mathematics-timeline-12::before {
  content: '';
  position: absolute;
  width: 5px;
  background-color: #e0e0e0;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}

/* Specific bottom properties for each tab */
.math_standard-year11::before {
  bottom: 130px; /* Adjust this value as needed */
}

.math_standard-year12::before {
  bottom: 180px; /* Adjust this value as needed */
}

.math_advanced-year11::before {
  bottom: 140px; /* Adjust this value as needed */
}

.math_advanced-year12::before {
  bottom: 160px; /* Adjust this value as needed */
}

.math_extension1-year11::before {
  bottom: 150px; /* Adjust this value as needed */
}

.math_extension1-year12::before {
  bottom: 150px; /* Adjust this value as needed */
}

.extension2-year12::before {
  bottom: 150px; /* Adjust this value as needed */
}

.extension2-year12-button {
  max-width: 920px; /* Adjust the width as needed */
}


.mathematics-statement {
  background-color: white;
  width: 70%;
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 20px auto 30px auto;
  position: relative;
  font-size: 30px;
  color: #0e1726;
}

.mathematics-bg {
  background-color: #0e1726;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  
}
@media (max-width: 1550px) {
  .mathematics-book-content button {
    width: 13%;
  }

  .mathematics-book-content {
    height: 60vh;
  }
}

@media (max-width: 1024px) {
  .mathematics-image-container {
    height: 350px;
  }

  .mathematics-book-content button {
    width: 35%;
    font-size: 14px;
  }

  .overlay-text-mathematics {
    font-size: 35px;
  }

  .mathematics-book-content h2 {
    font-size: 30px;
  }

  .mathematics-book-content p {
    font-size: 18px;
  }

  .mathematics-main-tab-button {
    font-size: 18px;
    width: 80%; /* Adjust for larger width on smaller screens */
  max-width: none;
  }

  .mathematics-sub-tab-button {
    font-size: 17px;
    width: 80%; /* Adjust for larger width on smaller screens */
  max-width: none;
  }

  .mathematics-timeline-content p {
    font-size: 16px;
  }

  .mathematics-timeline-content h3 {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .mathematics-image-container {
    height: 300px;
  }

  .overlay-text-mathematics {
    font-size: 32px;
  }

  .mathematics-book-content h2 {
    font-size: 28px;
  }

  .mathematics-book-content p {
    font-size: 17px;
  }

  .mathematics-main-tab-button {
    
      width: 90%; /* Make buttons even wider */
    
    font-size: 16px;
  }

  .mathematics-sub-tab-button {
    
    width: 90%; /* Make buttons even wider */
  
  font-size: 16px;
}

  .mathematics-timeline-content p {
    font-size: 15px;
  }

  .mathematics-timeline-content h3 {
    font-size: 19px;
  }
}

@media (max-width: 768px) {
  .mathematics-image-container {
    height: 250px;
  }

  .overlay-text-mathematics {
    font-size: 28px;
  }

  .mathematics-book-content h2 {
    font-size: 26px;
  }

  .mathematics-book-content p {
    font-size: 16px;
  }



  .mathematics-timeline-content p {
    font-size: 14px;
  }

  .mathematics-timeline-content h3 {
    font-size: 18px;
  }

  .mathematics-timeline-content {
    width: 350px;
  }
}

@media (max-width: 480px) {
  .mathematics-image-container {
    height: 170px;
  }

  .overlay-text-mathematics {
    font-size: 18px;
  }

  .mathematics-book::before {
    clip-path: polygon(70% 0, 100% 0, 100% 85%, 30% 100%, 0 100%, 0 15%);
     }

     .mathematics-book-content {
      width: 100%;
      height: 80vh;
     }

  .mathematics-book-content h2 {
    font-size: 30px;
    margin-top: 30px;
  }

  .mathematics-book-content p {
    font-size: 16px;
    width: 90%;
  }

  .mathematics-final {
    font-size: 15px;
   font-weight: 300;
    
  }

.mathematics-statement .mathematics-final {
  width: calc(120%);
  margin: 0px 0px 20px auto;
  transform: translateX(-10%);
}

  .mathematics-statement {
    margin: 0px auto;
  }

  .mathematics-name {
    font-size: 16px;
  }
  
  .mathematics-overview-container .mathematics-title {
    font-size: 30px;
  }

  .mathematics-overview-container .mathematics-subtitle {
    font-size: 16px;
    
  }

  .mathematics-main-tab-button {
    font-size: 13px;
    max-width: none !important; /* Remove any max-width restriction */
    width: 100% !important;  /* Make the tabs almost full width */
    padding-left: 3px;
    padding-right: 3px;
    font-weight: 300;
  }

  .mathematics-sub-tab-button {
    font-size: 16px;
    max-width: none !important; /* Remove any max-width restriction */
    width: 100% !important;  /* Make the tabs almost full width */
  
  }

  .mathematics-timeline-content p {
    font-size: 15px;
  }

  .mathematics-timeline-content h3 {
    font-size: 17px;
  }

  .mathematics-timeline-content {
    width: 300px;
  }

  .mathematics-tab-content {
    padding: 0 10px;
    
  }

  .mathematics-timeline-item {
    justify-content: flex-start;
    flex-direction: row-reverse; /* Align items on the right */
    padding-left: 50px; /* Space for the line on the left */
    padding-right: 0; /* Remove right padding */
  }

  .mathematics-timeline-item.left .mathematics-timeline-content::after {
    display: none; /* Remove the left arrow */
  }

  .mathematics-timeline-item.right .mathematics-timeline-content::after {
    display: none; /* Remove the right arrow */
  }

  .mathematics-timeline-icon {
    position: absolute;
    left: 25px; /* Position icon over the line */
    transform: translateY(-50%); /* Center icon vertically */
    top: 50%;
  }

  .mathematics-timeline::before {
    left: 35px; /* Align the line under the icon */
    transform: translateX(0);
    width: 5px; /* Ensure the line width matches the icon */
    
  }

  .mathematics-timeline-12::before {
    left: 35px; /* Align the line under the icon */
    transform: translateX(0);
    width: 5px; /* Ensure the line width matches the icon */
  
  }

  .math_standard-year11::before {
    bottom: 70px;
    top: 100px; /* Adjust this value as needed */
  }
  
  .math_standard-year12::before {
    bottom: 90px;
    top: 100px; /* Adjust this value as needed */
  }
  
  .math_advanced-year11::before {
    bottom: 70px;
    top: 90px; /* Adjust this value as needed */
  }
  
  .math_advanced-year12::before {
    bottom: 90px;
    top: 80px; /* Adjust this value as needed */
  }
  
  .math_extension1-year11::before {
    bottom: 80px;
    top: 80px; /* Adjust this value as needed */
  }
  
  .math_extension1-year12::before {
    bottom: 80px;
    top: 80px; /* Adjust this value as needed */
  }
  
  .extension2-year12::before {
    bottom: 80px;
    top: 90px; /* Adjust this value as needed */
  }
}