/* BenefitsSection.css */
.benefits-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0;
    background-color: white;
    margin-top: 20px;
}

.benefit {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 125px;
    color: white;
    font-size: 20px;
    position: relative;
}

.benefit p {
    position: relative;
    font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 22px;
}

.benefit p::before {
    content: '✔'; 
    color: #F39C12; 
    font-size: 24px; 
    margin-right: 10px; 
    

    
}

.benefit1 {
    background-color: #16243A;
}

.benefit2 {
    background-color: #0F1A2E;
}

.benefit3 {
    background-color: #0A1322;
}

@media (max-width: 768px) {
    .benefits-section {
        flex-direction: column;
    }
    .benefit {
        width: 100%;
       
    }
}

@media (max-width: 480px) {
    .benefits-section {
        flex-direction: column;
        display: none;
    }
    .benefit {
        width: 100%;
    }

  
}
