.home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  background-color: white;

}

.promo-section {
  display: flex;
  justify-content: space-evenly; 
}

.promo-item2 {
  width: 50vw;
  position: relative;
  height: auto;
  overflow: hidden;  
  border-left: 1px solid #0e1726;
}

.promo-item1 {
  width: 50vw;
  position: relative;
  height: auto;
  overflow: hidden;  
  border-right: 1px solid #0e1726;
}

.promo-item1 img, .promo-item2 img {
  width: 100%;
  height: 550px;
  object-fit: cover;
  display: block;
}

.promo-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(14, 23, 38, 0.8);
  backdrop-filter: blur(5px);
  padding: 20px;
  border-radius: 0px;
  color: white;
  text-align: center;
}

.online1, .f2f {
  margin-bottom: 25px
}

.book-button {
  padding: 10px 20px;
  background-color: #F39C12;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin-top: 100px;
}


.promo-button:hover, .book-button:hover {
  background-color: #cd3232;
}


.call-to-action {
  background-color: #0e1726;
  color: white;
  text-align: center;
  padding: 100px 20px;
  margin-top: 0px;
  height: auto;
  
}



.call-to-action h1{
margin-bottom: 30px;
font-family: "Akshar", sans-serif;
font-weight: 400;
font-size: 40px;
}

.body, .body2 {
  font-size: 22px;
  color: white;
  text-align: center;
  width: 60%; /* Use percentage to determine the width */
  margin: 0 auto 40px auto; /* Center the text block */
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.5;
}

.body3 {
  font-size: 22px;
  color: white;
  text-align: center;
  width: 60%; /* Use percentage to determine the width */
  margin: 0 auto 80px auto; /* Center the text block */
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.5;
}

 


@media (max-width: 992px) {
  

  
}

@media (max-width: 768px) {
  .call-to-action, .body, .body2, .body3 {
    font-size: 16px; /* Further reduce font size for readability */
    width: 100%; /* Increase width percentage to fit smaller screens */
    margin: 0 auto; /* Center the text block */
    padding: 10px 15px; /* Ensure content doesn't touch edges */
    text-align: center; /* Center text for better aesthetics */
  }

 
  .call-to-action h1 {
    font-size: 20px;
    padding: 10px 15px;
    margin-bottom: 0px;
  }


  .call-to-action {
    padding-bottom: 40px;
  }
}

@media (max-width: 480px) {

  .body, .body2, .body3 {
    width: 100%; /* Further adjust width to ensure full usage of space */
    margin: 10px auto; /* Center the text block */
    font-size: 15px;
  }

 .call-to-action h1 {
    font-size: 25px;
    margin-bottom: 0px;
    width: 100%;
  }

  .book-button {
    font-size: 14px;
   
  }
}

@media (max-width: 360px) {
  .promo-content {
    font-size: 12px; /* Very small text adjustments for very small screens */
  }

  .body, .body2, .body3 {
    width: 90%; /* Further adjust width to ensure full usage of space */
    margin: 10px auto; /* Center the text block */
  }
}