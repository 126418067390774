.form-container {
  width: 100%; /* Take full width of the screen */
  padding: 40px; /* Internal padding for content */
  background-color: #0e1726;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* Center horizontally */
  color: white;
  
  box-sizing: border-box; /* Include padding in the width and height calculations */
  display: flex;
  justify-content: center; /* Center the nested container horizontally */
}

.form-content {
  width: 100%;
  max-width: 1000px; /* Maximum width for the content */
  background-color: #0e1726;
  text-align: left;
  padding-left: 20px; 
  padding-right: 20px;
  padding-bottom: 100px;/* Add some padding to the sides */
  box-sizing: border-box;
}

.form-container h2 {
  text-align: center;
  
  margin-bottom: 40px;
  color: #F39C12;
  font-family: "Akshar", sans-serif;
font-weight: 400;
font-size: 40px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

input[type="text"], input[type="email"], select, textarea {
  width: 100%;
  padding: 10px 0;
  margin-top: 5px;
  border: none;
  border-bottom: 1px solid #ccc;
  background: transparent;
  color: white;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.2;
}

::placeholder {
  color: white;
  font-weight: normal;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  color: white;
  font-weight: normal;
}

option {
  background: #0e1726;
  color: white;
}

select:focus, input[type="text"]:focus, input[type="email"]:focus, textarea:focus {
  outline: none;
  border-bottom: 1px solid #F39C12;
  font-weight: normal;
}

.form-button {
  background-color: #F39C12;
  color: #0e1726;
  padding: 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
}

button:hover {
  background-color: #cd3232;
}

.form-group {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.2;

}

.form-group.row {
  flex-direction: row;
  gap: 50px;
}

.checkbox-group {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  gap: 15px;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  width: calc(50% - 10px); /* Adjust width to fit two items per row */
  margin-bottom: 5px;
}

.checkbox-group input {
  margin-right: 10px;
  
}

@media (max-width: 1024px) {
  .form-container h2 {
    font-size: 28px;
  }

  input[type="text"],
  input[type="email"],
  select,
  textarea {
    font-size: 18px;
  }

  .checkbox-group {
    font-size: 18px;
  }

  .form-group{
    font-size: 18px;
  }

  .form-button {
    font-size: 17px;
  }

  .form-group.row {
    gap: 40px;
  }
}

@media (max-width: 992px) {
  .form-container h2 {
    font-size: 26px;
  }

  input[type="text"],
  input[type="email"],
  select,
  textarea {
    font-size: 17px;
  }

  .checkbox-group {
    font-size: 17px;
  }

  .form-group{
    font-size: 17px;
  }

  .form-button {
    font-size: 16px;
  }

  .form-group.row {
    gap: 30px;
  }
}

@media (max-width: 768px) {
  .form-group.row {
    flex-direction: column;
  }

  .checkbox-group label {
    width: 100%; /* Make the labels full-width on smaller screens */
  }

  .form-container h2 {
    font-size: 24px;
  }

  input[type="text"],
  input[type="email"],
  select,
  textarea {
    font-size: 16px;
  }

  .checkbox-group {
    font-size: 16px;
  }

  .form-group{
    font-size: 16px;
  }

  .form-button {
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .form-container h2 {
    font-size: 30px;
  }

  input[type="text"],
  input[type="email"],
  select,
  textarea {
    font-size: 16px;
  }

  .checkbox-group {
    font-size: 16px;
  }

  .form-group{
    font-size: 16px;
  }

  .form-button {
    font-size: 14px;
  }


  .form-group.row {
    gap: 20px;
  }
}
