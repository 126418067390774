@media (max-width: 768px) {

.logo {
    width: auto;
    height: 25px;
    margin-left: 0;
}
/* Remove any border from the hamburger button */
.hamburger {
    border: none;
    background-color: none; /* Ensure no border is applied */
}

/* Remove any outline that might appear when the button is focused */
.hamburger:focus {
    outline: none; /* Remove focus outline */
}

/* Remove any potential border from the nav-bar or other elements */
.nav-bar {
    border: none; /* Ensure no border is applied */
}

/* If there is a border caused by box-shadow, remove it */
.hamburger, .nav-bar {
    box-shadow: none; /* Remove box-shadow if any */
}

.hamburger {
    background-color: rgba(14, 23, 38, 0.9) !important;
    border: none !important;
    box-shadow: none !important;
}

.hamburger:focus,
.hamburger:active {
    background-color: rgba(14, 23, 38, 0.9) !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}


    .nav-bar {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 55px;
        right: 0;
        background-color: #0e1726;
        width: 100%;
        text-align: center;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        height: 100vh; /* Ensure the nav-bar only takes the necessary height */
        align-items: flex-start; /* Align items to the top */
        border-top:#F39C12 2px solid
    }

    .nav-bar.open {
        display: flex;
        max-height: 100vh;
    }

    .nav-list {
        flex-direction: column;
        width: 100%;
        align-items: flex-start; /* Align list items to the top */
        padding-top: 0px; /* Add some padding at the top if needed */
    }

    .nav-list li {
        margin: 0px 0; /* Increase spacing between menu items */
        font-size: 18px; /* Increase font size */
        font-family: "Akshar";
        font-weight: bold;
        width: 100%; /* Ensure the list items take full width */
        /* border-bottom: #161f30 1px solid; */
    }

    .header-button {
        font-size: 30px; /* Increase font size */
        font-weight: 500;
        font-family: "Akshar";
        width: 100%; /* Ensure the button takes full width */
        text-align: left; /* Align text to the left */
        padding: 12px 16px; /* Add some padding for spacing */
    }

    .dropdown-content {
        display: none;
        flex-direction: column;
        background-color: rgba(14, 23, 38, 0.9);
        width: 100%;
        padding: 0;
        transition: max-height 0.3s ease-in-out;
        overflow: hidden;
        max-height: 0;
        margin-left: 5%;
    }

    .dropdown-content a {
        padding: 12px 16px;
        border-top: 2px solid #F39C12;
        text-align: left;
    }

    .dropdown-content.show {
        display: flex;
        max-height: 500px;
    }

    .dropdown > .header-button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;
        font-weight: 500;
        font-family: "Akshar";
    }

    .dropdown > .header-button::after {
        transform: rotate(0deg);
        transition: transform 0.3s ease;
    }

    .dropdown.open > .header-button::after {
        transform: rotate(180deg);
    }

    /* Hamburger Menu Styles */
    .hamburger {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        background-color: rgba(14, 23, 38, 0.9);
        border-radius: 2px;
        margin-right: 4px;
        position: relative;
        width: 30px;
        height: 24px;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
    }

    .hamburger .line {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background-color: white;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }

    .hamburger .line:nth-child(1) {
        top: 0px;
    }

    .hamburger .line:nth-child(2),
    .hamburger .line:nth-child(3) {
        top: 10px;
    }

    .hamburger .line:nth-child(3) {
        top: 20px;
    }

    /* Animation for when the menu is open */
    .hamburger.open .line:nth-child(1) {
        top: 10px;
        transform: rotate(135deg);
    }

    .hamburger.open .line:nth-child(2) {
        opacity: 0;
        left: 60px;
    }

    .hamburger.open .line:nth-child(3) {
        top: 10px;
        transform: rotate(-135deg);
    }

    .book {
        font-size: 18px;
        margin-top: 40px;
        width: 200px;
        border-radius: 40px;
        padding: 8px 0 8px 0;
    }

    .header.open {
        border-bottom: 2px solid #F39C12; /* Ensure the border remains when the menu is open */
    }

    .header-social {
        display: flex; /* Use flexbox to center the content */
        justify-content: center; /* Horizontally center the items */
        align-items: center; /* Vertically center the items */
        padding-bottom: 10px;
        color: white;
        width: 100%; /* Ensure it takes the full width of the container */
        text-align: center; /* Center text content */
    }
      
      .header-social a {
        color: white;
        margin: 0 15px;
        font-size: 20px;
        margin-top: 30px;
      }
      
      .header-social a:hover {
        color: #F39C12;
      }
    
}
