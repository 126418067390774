.steps-container {
  text-align: center;
  padding-top: 50px;
  width: 100%;
  background-image: url('../../assets/pattern2.png'); 
  background-size: cover;
  background-position: center; 
  margin-top: 50px;
  padding-bottom: 100px;
  position: relative;
  z-index: 1;
}

.steps-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* White overlay with 50% opacity */
  z-index: 1; /* Overlay is above the background but below content */
  pointer-events: none;
}

.steps-content, .steps-intro {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
}

.steps-intro {
    font-size: 22px;
    margin-bottom: 40px;
    color: #0e1726;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    line-height: 1.5;
}

.steps-content {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.step {
    background-color: #0e1726;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step-number {
    background-color: #BABDDD;
   
    /* Adjust this color to your design */
    color: #000000;
    font-size: 40px;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30%;
    text-align: center;
   
}

.step h2 {
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 20px;
    color: #F39C12;
   

}

.step p {
    font-size: 18px;
    margin-bottom: 20px;
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: 1.5;
}

@media (max-width: 1024px) {
    .steps-intro {
      font-size: 20px;
      margin-bottom: 35px;
    }
  
    .steps-content {
      gap: 75px;
    }
  
    .step {
      max-width: 350px;
      padding: 18px;
    }
  
    .step-number {
      font-size: 38px;
      width: 65px;
      height: 65px;
    }
  
    .step h2 {
      font-size: 28px;
    }
  
    .step p {
      font-size: 17px;
    }
  }
  
  @media (max-width: 992px) {
    .steps-intro {
      font-size: 20px;
      margin-bottom: 30px;
    }
  
    .steps-content {
      gap: 40px;
    }
  
    .step {
      max-width: 330px;
      padding: 16px;
    }
  
    .step-number {
      font-size: 36px;
      width: 60px;
      height: 60px;
    }
  
    .step h2 {
      font-size: 27px;
    }
  
    .step p {
      font-size: 16px;
    }
  }
  
  @media (max-width: 768px) {
    .steps-intro {
      font-size: 20px;
      margin-bottom: 30px;
    }
  
    .steps-content {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  
    .step {
      max-width: 500px;
      padding: 15px;
    }
  
    .step-number {
      font-size: 35px;
      width: 60px;
      height: 60px;
    }
  
    .step h2 {
      font-size: 26px;
    }
  
    .step p {
      font-size: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .steps-intro {
      font-size: 16px;
      margin-bottom: 25px;
      width: 90%;
      margin: 20px auto;
      margin-top: 0;
    }
  
    .steps-container {
      padding-top: 30px;
      padding-bottom: 50px;
    }
  
    .step {
      max-width: 90%;
      padding: 20px;
    }
  
    .step-number {
      font-size: 25px;
      width: 40px;
      height: 40px;
      margin-bottom: 20px;
    }
  
    .step h2 {
      font-size: 24px;
    }
  
    .step p {
      font-size: 15px;
    }
  }