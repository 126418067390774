.testimonials-container {
    padding: 45px 0px;
    background-color: #fff; 
    text-align: center; 
    margin-top: 20px; 
  
    margin-left: 200px;
    margin-right: 200px;
    height: 50vh;
    position: relative; /* Allows absolute positioning of the slider */
}
.testimonials-title h1{
    font-size:40px;
    margin-top: 20px;
    font-family: "Akshar", sans-serif;
    font-weight: 400;
    color:#0e1726;
    font-style: normal;
}
.slick-slider {
    position: relative; /* Position the slider absolutely within the container */
    top: 40%; /* Center the slider vertically */
    left: 50%;
    transform: translate(-50%, -50%); /* Adjust for exact centering */
    width: 90%; /* Ensures slider takes full width of container */
}
  
.testimonial p {
    font-size: 22px; 
    color: #0e1726; 
    margin-bottom: 40px; /* Space between text and author */
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}
  
.author {
    font-size: 20px;
    font-weight: bold;
    color: #333; 
    display: block; 
    margin-bottom: 10px; /* Space between author and location */
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

.location {
    font-size: 16px; 
    color: #333;
    display: block; 
    margin-bottom: 50px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}

.slick-dots li button:before {
    font-size: 12px; 
    color: #585858; 
}

.slick-dots li.slick-active button:before {
    color: #F39C12;
}
@media (max-width: 1550px) {
.testimonials-container {
    height: 60vh;
}
}
@media (max-width: 992px) {
    .testimonials-container {
        width: 80%; /* Increase width percentage for more space on smaller screens */
        margin-left: auto;
        margin-right: auto;
        height: 40vh
    }

    .testimonial p {
        font-size: 18px; /* Adjust font size for readability */
        margin-bottom: 20px;
    }

    .author {
        font-size: 16px;
    }

    .location {
        font-size: 14px;
        margin-bottom: 30px;
    }
}

@media (max-width: 768px) {
    .testimonials-container {
        padding: 10px 10px; /* Adjust padding for smaller screens */
        width: 90%; /* Use more of the available screen width */
        height: 30vh
    }

    .slick-slider {
        width: 90%;
    }
    .testimonials-title h1{
        font-size: 30px;
    }
    .testimonial p {
        font-size: 16px; /* Further adjust font size for smaller devices */
        margin-bottom: 20px;
    }

    .author {
        font-size: 15px;
        margin-bottom: 10px;
    }

    .location {
        font-size: 13px;
        margin-bottom: 5px;
    }
}

@media (max-width: 480px) {
    .testimonials-container {
        padding: 15px 10px; /* Reduce padding for very small screens */
        width: 95%; /* Maximize usage of available width */
        margin-bottom: 20px;
    }
    .testimonials-title {
display: none;
    }

    .testimonial p {
        font-size: 20px; 
        color: #0e1726; 
        margin-bottom: 15px; /* Space between text and author */
    }
      
    .author {
        font-size: 17px;
        font-weight: bold;
        color: #333; 
        display: block; 
        margin-bottom: 4px; /* Space between author and location */
    }
    
    .location {
        font-size: 15px; 
        color: #333;
        display: block; 
        margin-bottom: 10px;
    }

    .testimonial p {
        font-size: 15px; /* Smaller font size for better readability on narrow screens */
    }

    .author {
        font-size: 14px;
    }

    .location {
        font-size: 12px;
    }
}

@media (max-width: 360px) {
    .testimonials-container {
        padding: 10px 5px; /* Further reduce padding for smallest screens */
        width: 100%; /* Utilize full width on very small screens */
    }

    .testimonial p {
        font-size: 14px; /* Minimal font size to maintain readability */
    }

    .author {
        font-size: 13px;
    }

    .location {
        font-size: 11px;
    }
}
