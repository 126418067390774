/* Header styles */

.logo {
  width: auto;
  height: 40px;
  margin-left: 20px;
}
.header {
  position: sticky;
  top: 0;
  background-color: #0e1726;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  border-bottom: #F39C12 solid 2px;
}

.header .logo a {
  color: white;
  text-decoration: none;
  font-size: 24px;
}

.nav-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

nav ul {
  list-style: none;
  margin: 4px;
  padding: 0;
  display: flex;
}

nav ul li {
  margin-left: 48px;
  position: relative;
}

nav button {
  background: none;
  border: none;
  color: white;
  
  cursor: pointer;
  font-family: 'Cairo', sans-serif;
  font-weight: 400;
  font-size: 17px;
  
}

.header-button:hover,
.header-button:focus {
  color: #F39C12;
  background: none;
}

.book {
  background-color: #F39C12;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.book:hover {
  background-color: #cd3232;
}

/* Ensure the "Book Now" button doesn't change text color on hover */
nav .book:hover {
  color: white; /* Retain white text color */
}

/* Dropdown content */
.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(14, 23, 38, 0.9);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 40px 16px 12px 16px;
  border-radius: 4px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Show dropdown when hovered or focused, and also when 'open' class is added */
.dropdown:hover .dropdown-content,
.dropdown:focus-within .dropdown-content,
.dropdown.open .dropdown-content {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-content a {
  color: white;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  white-space: nowrap;
  transition: background-color 0.3s;
  font-family: 'Cairo', sans-serif;
  font-weight: 400;
  font-size: 17px;
}



.dropdown-content a:hover {
  color: #F39C12;
  border-radius: 4px;
}



.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  background-color: rgba(14, 23, 38, 0.9);
  border-radius: 2px;
  margin-right: 4px;
}

.dropdown-content::before {
  content: '';
  position: absolute;
  
  top: 28px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #F39C12; /* Yellow bar */
}

.header.open {
  border-bottom: #F39C12 solid 2px; /* Reapply the yellow bottom border */
}

.header-social {
  display: none;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  color: white;
}

.header-social a {
  color: white;
  margin: 0 15px;
  font-size: 30px;
  margin-top: 10px;
}

.header-social a:hover {
  color: #F39C12;
}
@media (max-width: 768px) {
  .header {
      border-bottom: #F39C12 solid 2px; /* Ensure the border is present in mobile view */
    
  }

  .header.open {
      border-bottom: #F39C12 solid 4px; /* Reapply the border when the menu is open */
  }
}
