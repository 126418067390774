.footer {
  color: white;
 
  overflow: hidden;
}



.footer-promo {
  background-color: #16243A;
  padding: 110px 50px;
  text-align: center;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 90%);
  z-index: 1;
}

.footer-question {
  background-color: #16243A;
  padding: 110px 50px;
  text-align: center;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 90%);
  z-index: 1;
}

.footer-promo p {
  margin: 10px 0;
  font-size: 20px;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.5;
}

.footer-question p {
  margin: 10px 0;
  font-size: 20px;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.5;
}

.footer-promo h1 {
  margin: 10px 0;
  font-size: 30px;
  align-items: center;
  font-family: "Akshar", sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

.footer-question h1 {
  margin: 10px 0;
  font-size: 30px;
  align-items: center;
  font-family: "Akshar", sans-serif;
  font-weight: 500;
  line-height: 1.5;
}

.footer-promo button {
  background-color: #F39C12;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  cursor: pointer;
  margin-top: 30px;
  border: none;
  outline: none;
  box-shadow: none;
}

.footer-question button {
  background-color: #F39C12;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  cursor: pointer;
  margin-top: 30px;
  border: none;
  outline: none;
  box-shadow: none;
}

.footer-promo button:hover,
.footer-promo button:focus {
  background-color: #cd3232;
  outline: none;
}

.footer-question button:hover,
.footer-question button:focus {
  background-color: #cd3232;
  outline: none;
}

/* Default background colors */
.footer-content, .footer-location, .footer-social, .footer-legal {
  background-color: #0e1726;
}



.footer-content {
  display: flex;
  justify-content: space-around;
  padding-top: 100px;
  padding-bottom: 100px;
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
  z-index: -1;
  margin: -70px auto 0;
  overflow-x: hidden;
  text-align: center;
}

.footer-content-width {
  width: 1400px;
  display: flex;
  justify-content: space-around;
}

.footer-section h4 {
  margin-bottom: 20px;
  font-size: 27px;
  font-family: "Akshar", sans-serif;
font-weight: 500;

  color: #F39C12;

}

.footer-section ul {
  list-style: none;
  padding: 0;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
 
}

.footer-section ul li {
  margin-bottom: 20px;
  color: white;
  font-size: 18px;
}

.footer-section a {
  text-decoration: none;
  color: white;
  transition: color 0.3s, transform 0.3s;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.footer-section ul li a:hover {
  color: #87c1ed;
  transform: translateY(-3px);
}

.subjects-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-location {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  padding-top: 20px;
  color: white;
  font-size: 20px;
  margin-top: -20px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.footer-location span {
  margin-right: 50px;
}

.footer-social {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  color: white;
}

.footer-social a {
  color: white;
  margin: 0 15px;
  font-size: 30px;
  margin-top: 10px;
}

.footer-social a:hover {
  color: #F39C12;
}

.footer-section,
.footer-location {
  margin-bottom: 0;
  border: none;
  outline: none;
}

.footer-icon {
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
}

.footer-icon svg {
  width: 24px;
  height: 24px;
  fill: #F39C12;
  vertical-align: middle;
  margin-right: 15px;
}

.footer-promo li {
  list-style: none;
}

.footer-question li {
  list-style: none;
}

.footer-legal {
  padding: 50px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
flex-direction: column;
}

.footer-legal a{
  
 
  text-decoration: none;
  color: white;
 
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.footer-legal li{
  list-style: none;
 margin-top: 10px;

}



@media (max-width: 1024px) {
  .footer-promo {
    padding: 90px 30px;
  }

  .footer-promo p {
    font-size: 18px; /* Reduced font size for tablets */
  }

  .footer-content-width {
    width: 90%;
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-section {
    width: 30%;
  }
}

@media (max-width: 992px) {
  .footer-content-width {
    width: 95%;
    flex-direction: column;
    align-items: center;
  }

  .footer-content {
    clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 100%);
  }  

  .footer-section h4 {
    font-size: 20px;
  }

  .footer-section a {
    font-size: 18px;
  }
  
  .footer-section {
    width: 80%;
    text-align: center;
    margin-bottom: 20px;
  }

  .footer-promo p {
    font-size: 18px; /* Adjust font size */
  }

  .subjects-list {
    grid-template-columns: 1fr;
  }

  .footer-location {
    flex-direction: column;
    padding-bottom: 30px;
  }

  .footer-location span {
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .footer-promo {
    padding: 80px 20px;
  }

  .footer-promo p {
    font-size: 16px; /* Smaller font size for smaller screens */
  }

  .footer-content {
    padding: 80px 20px;
    flex-direction: column;
  }

  .footer-content {
    clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 100%);
  }  

  .footer-section h4 {
    font-size: 20px;
  }

  .footer-section a {
    font-size: 16px;
  }

  .footer-location {
    padding-bottom: 30px;
    padding-top: 10px;
    font-size: 18px;
    margin: 0;
  }

  .footer-social a {
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  .footer-content-width {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .footer-question {
   padding: 80px 30px 110px 30px;

  }

  .footer-question {
    padding: 80px 30px 110px 30px;
 
   }
   
  .footer-promo h1 {
    font-size: 30px;
  }

  .footer-question h1 {
    font-size: 30px;
  }

  .footer-promo p {
    font-size: 15px; /* Smaller font size for mobile devices */
  }

  .footer-promo button {
    font-size: 15px;
  }

  .footer-question p {
    font-size: 15px;
  }

  .footer-question button {
    font-size: 15px;
  }

  .footer-section {
    display: none;
 
  }

  .footer-content {
    display: none;
  }

  .footer-location {
    padding-bottom: 40px;
    margin: 0px;
    font-size: 16px;
    flex-direction: column;
    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
    padding-top: 80px;
    margin-top: -50px;
  }


  .footer-location span {
    margin-top: 20px;
   margin-bottom: 0;
    margin-right: 0;
  }

  .footer-social a {
    font-size: 26px;
  }

  .footer-legal p {
    font-size: 12px;
  }

  .footer-legal a{
  
 
    text-decoration: none;
    color: white;
   
    font-size: 12px;
    font-family: "Roboto", sans-serif;
  }
}

@media (max-width: 360px) {
  .footer-promo {
    padding: 60px 10px;
  }

  .footer-promo p {
    font-size: 14px; /* Further reduced font size for very small screens */
  }

  .footer-section h4 {
    font-size: 18px;
  }

  .footer-section a {
    font-size: 14px;
  }

  .footer-location {
    font-size: 14px;
  }

  .footer-social a {
    font-size: 24px;
  }
}