* {
    box-sizing: border-box; /* Ensure padding and borders are included in width/height */
    margin: 0;
    padding: 0;
}

.why-prime-container {
    position: relative;
    text-align: center;
    padding: 40px 20px;
    background-color: #0e1726;
    width: 100%;
    overflow: hidden;
    z-index: 0;
}

.why-prime-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #b6d9f5;
    clip-path: polygon(0 60%, 100% 40%, 100% 100%, 0 100%);
    z-index: -1;
}

.whytitle, .features-grid {
    position: relative;
    z-index: 2;
    color: white;
    font-size: 30px;
    overflow-x: hidden;
}

.whytitle {
    font-family: "Akshar", sans-serif;
font-weight: 400;
font-size: 40px;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-width: 1800px;
    margin: auto;
    padding: 40px 20px;
    overflow: hidden;
}

.feature {
    background: white;
    border-radius: 8px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature:hover {
    transform: translateY(-7px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3);
}

.feature .feature-icon1,
.feature .feature-icon2,
.feature .feature-icon3,
.feature .feature-icon4 {
    margin-bottom: 10px;
    margin-top: 20px;
    fill: #0A1322;
}

.feature .feature-icon1,
.feature .feature-icon2 {
    width: 40px;
    height: 40px;
}

.feature .feature-icon3,
.feature .feature-icon4 {
    width: 50px;
    height: 50px;
}

.feature h3 {
    margin-top: 10px;
    font-size: 21px;
    color: #0A1322;
    font-family: "Roboto", sans-serif;
    font-weight: 700;

}

.feature p {
    text-align: center;
    font-size: 18px;
    color: #0A1322;
    
    margin-top: 20px;
    margin-bottom: 5px;
    max-width: 700px;;
    font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.5;
}

@media (max-width: 768px) {
    .why-prime-container {
        padding: 20px 10px; /* Reduce padding that might be adding to the height */
        transform: translateY(0%); /* Remove any vertical shift that might create gaps */
    }

    .features-grid {
        grid-template-columns: 1fr; /* Stack features in a single column */
        padding: 20px; /* Adjust padding */
    }

    .feature {
        padding: 15px;
        margin-bottom: 0; /* Remove bottom margin that might add unnecessary space */
    }

    .feature .feature-icon {
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
    }

    .feature h3 {
        font-size: 18px;
    }

    .feature p {
        font-size: 15px;
    }

    .whytitle {
        font-size: 22px;
    }
}