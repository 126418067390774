.booking-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.image-section-book {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
}

.book-image {
  position: absolute;
  top: 100%; /* Push the top of the image to align at the bottom of the container */
  left: 0;
  width: 100%;
  height: auto; /* Allow natural height to maintain aspect ratio */
  transform: translateY(-80%); /* Shift the image back up by its own height */
  object-fit: cover; /* Cover the area without distorting the aspect ratio */
}

.overlaybook {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-text-book {
    color: white;
    text-align: center;
    font-size: 80px;
    font-weight: bold;
    z-index: 2;
}

.content-section-book {
    background-color: white;
    width: 1000px;
    padding: 40px 20px;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.content-section-book h1 {
   
    color: #0e1726;
    margin-bottom: 40px;
    font-family: "Akshar", sans-serif;
font-weight: 400;
font-size: 40px;
}

.content-section-book p {
    font-size: 22px;
    color: #0e1726;
    margin-bottom: 60px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: 1.5;

}

.contact-deets {
    display: flex;
    justify-content: center;
    gap: 50px;
}

.contact-deets p {
    font-size: 18px;
    color: #0e1726;
    display: flex;
    align-items: center; /* Ensure icons and text are vertically aligned */
    margin: 0;
    
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: 1.5;
}

.icon {
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
}

.icon svg {
    width: 24px; /* Adjusted size to match text height */
    height: 24px;
    fill: #0e1726; /* Optional: Set the color of the SVG icon */
    vertical-align: middle;
}

@media (max-width: 1550px) {
.content-section-book {
  height: 70vh;
}
}

@media (max-width: 1024px) {
    .overlay-text-book {
      font-size: 70px;
    }
  
    .image-section-book {
      height: 300px;
    }

    .content-section-book {
        width: 90%
    }
  }
  
  @media (max-width: 992px) {
    .overlay-text-book {
      font-size: 60px;
    }
  
    .content-section-book h1 {
      font-size: 32px;
    }
  
    .content-section-book p {
      font-size: 18px;
    }
  }
  
  @media (max-width: 768px) {
    .overlay-text-book {
      font-size: 50px;
    }
  
    .content-section-book h1 {
      font-size: 30px;
    }
  
    .content-section-book p {
      font-size: 16px;
    }
  
    .contact-deets {
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }
  }
  
  @media (max-width: 480px) {
    .overlay-text-book {
      font-size: 36px;
    }
  
    .image-section-book {
      height: 170px;
    }
  
    .content-section-book {
      padding: 20px 10px;
      margin-top: 25px;
    }
  
    .content-section-book h1 {
      font-size: 30px;
      margin-bottom: 20px;
    }
  
    .content-section-book p {
      font-size: 15px;
    }
  
    .contact-deets p {
      font-size: 15px;
    }
  
    .icon svg {
      width: 20px;
      height: 20px;
    }
  }
  
  @media (max-width: 360px) {
    .overlay-text-book {
      font-size: 30px;
    }
  
    .content-section-book h1 {
      font-size: 22px;
    }
  }