.face-prime {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .image-container-face {
    position: relative;
    width: 100%;
    height: 400px; /* Fixed height */
    overflow: hidden; /* Ensures nothing extends beyond the container size */
  }
  
  .image-container-face::before {
    display: none; /* Hide any pseudo-elements that might be adding unwanted backgrounds */
  }

  .overlay2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 0;
}
  
.face-image {
  position: absolute;
  top: 100%; /* Push the top of the image to align at the bottom of the container */
  left: 0;
  width: 100%;
  height: auto; /* Allow natural height to maintain aspect ratio */
  transform: translateY(-80%); /* Shift the image back up by its own height */
  object-fit: cover; /* Cover the area without distorting the aspect ratio */
}
  
  .overlay-text-face {
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Align center perfectly */
    color: white; /* white color for visibility */
    text-align: center;
    width: 100%;
    font-size: 40px;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .face-content {
    width: 60%;
    padding-top: 60px;
    padding-bottom: 75px;
    text-align: center;
    color: #0A1322;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .face-content h1, .face-content h2 {
    color: #0e1726;
    margin-bottom: 40px;
    font-size: 40px;
    font-family: "Akshar", sans-serif;
    font-weight: 400;
   
  }
  
  .face-content p {
    font-size: 22px;
    color: #0A1322;
    
    margin-bottom: 20px;
    font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  }
  
 
  @media (max-width: 1550px) {
    .face-content {
      height: 120vh;
    }
    
  }
 /* Responsive styles */
@media (max-width: 1024px) {
  .face-image {
    top: 0; /* Align the top of the image to the container */
    transform: translateY(0); /* No translation, so the image fits perfectly at the top */
    height: 100%; /* Adjust height to ensure the image covers the full container */
    transform: scale(1.5);
  }
}

@media (max-width: 992px) {
  .face-image {
    top: 0; /* Align the top of the image to the container */
    transform: translateY(0); /* No translation, so the image fits perfectly at the top */
    height: 100%; /* Adjust height to ensure the image covers the full container */
  }
}

@media (max-width: 768px) {
  .overlay-text-face h1{
    font-size: 70px;
  }


}

@media (max-width: 480px) {
  .overlay-text-face h1{
    font-size: 36px;
  }

  .image-container-face {
    height: 170px;

  }
  .face-image {
    transform: scale(1.5);
    height: 200px;
    
}

.face-content h2{
  font-size: 30px;
  margin-bottom: 20px;
}

.face-content {
  width: 80%;
  height: 140vh;
}
.face-content p{
  font-size: 16px;
  
 
}
}
@media (max-width: 360px) {
  .overlay-text-face h1{
    font-size: 36px;
  }
}
  
  
  