.boost-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0e1726; /* Adjust background color as needed */
    padding: 50px 100px 50px 300px;
    /* padding: 50px 300px; */
    color: white;
    height: 100vh;
  }
  
  .boost-content {
    width: 100%;
    margin-right: 5%
  }
  
  .boost-mobile {
    display: none;
  }
  .boost-content h1 {
    font-size: 45px;
    line-height: 1.2;
    margin-bottom: 5%;
  }
  
  .highlight-purple {
    color: #F39C12; /* Purple color */
  }
  
  .highlight-orange {
    color: #F39C12; /* Orange color */
  }

  .boost-content p {
    font-size: 22px;
    margin-bottom: 20px;
    line-height: 1.5;
    font-family: "Roboto", sans-serif;
  font-weight: 300;
  }
  

  
  .boost-images {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .boost-image1 {
    width: 400px; /* Adjust width as needed */
    height: 400px;
 
    border-radius: 10px;
    object-fit: cover;
    
  }
  
  .boost-image2 {
    width: 400px; /* Adjust width as needed */
    height: 400px;
    border-radius: 10px;
    object-fit: cover;
    
  }

  .boost-image1:hover, .boost-image2:hover {
    transform: scale(1.02);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  }

  .custom-link:hover {
    color:#F39C12;
  }

  .image-text {
    margin-top: 10px;
    color: white;
    font-size: 24px;
    font-weight: bold;
    width: 100%
}
.custom-link {
    color: white; /* Set the text color */
    text-decoration: none;
}

.arrow-icon {
    margin-left: 8px; /* Space between icon and text */
    width: 25px;
    height: 25px;
    vertical-align: bottom;
}

/* Responsive adjustments */
@media (max-width: 1550px) {

    .boost-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #0e1726; /* Adjust background color as needed */
        padding: 50px 100px 50px 100px;
        /* padding: 50px 300px; */
        color: white;
        height: 100vh;
      }

    .boost-image1 {
        width: 350px; /* Adjust width as needed */
        height: 350px;
     
        border-radius: 10px;
        object-fit: cover;
        
      }
      
      .boost-image2 {
        width: 350px; /* Adjust width as needed */
        height: 350px;
        border-radius: 10px;
        object-fit: cover;
        
      }

      .boost-content h1 {
        font-size: 40px;
        line-height: 1.2;
        margin-bottom: 5%;
      }

}

@media (max-width: 1024px) {
    .boost-section {
        padding: 40px 40px 40px 150px;
        height: auto;
    }
    .boost-content {
        width: 45%;
    }
    .boost-image1, .boost-image2 {
        width: 350px;
        height: 350px;
    }
}

@media (max-width: 992px) {
    .boost-section {
        padding: 40px;
        
    }
    .boost-content {
        width: 100%;
        margin-right: 5%;
    }
    .boost-images {
        flex-direction: column;
    }
    .boost-image1, .boost-image2 {
        width: 300px;
        height: 300px;
    }
}

@media (max-width: 768px) {
    .boost-section {
        padding: 30px;
    }
    .boost-content h1 {
        font-size: 2.5rem;
    }
    .boost-image1, .boost-image2 {
        width: 250px;
        height: 250px;
    }
    .boost-content p {
        font-size: 18px;
    }
    .image-text {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .boost-section {
        padding: 20px;
        flex-direction: column;
        align-items: center;
        text-align: left;
        padding-left: 10%;
      
    }

    .boost-content h1 {
        font-size: 25px;
        margin-top: 20px;
    }

    .boost-content p{
      font-size: 16px;
    }

    .boost-desktop{
        font-size: 16px;
        display: none;
    }
   
    .boost-mobile {
      display: block;
      font-size: 15px;
    }
    .boost-images {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 40px; /* Adjust gap as needed */
  }

.boost-image1 {
  height: 150px;
  width: 150px;
}

.boost-image2 {
  height: 150px;
  width: 150px;
  transform: translateX(-10%);
}

.image-text {
  font-size: 15px;
}

.arrow-icon {
  margin-left: 0px; /* Space between icon and text */
  width: 15px;
  height: 15px;
  vertical-align: bottom;
}
}