

.privacy-policy-container {
    
    color: #333;
    
    padding: 20px;
  }
  
 
  

  
  .privacy-policy-content .container {
    max-width: 80vw;
    margin: 20px auto;
    padding: 20px;
    background-color: white;
    
  }
  
  .privacy-policy-content h1 {
    font-family: "Akshar", sans-serif;
    font-weight: 400;
    font-size: 40px;
    
    margin-bottom: 20px;
    color: #0e1726;
  }
  
  .privacy-policy-content h2 {
    font-family: "Akshar", sans-serif;
    font-weight: 400;
    font-size: 30px;
    margin-top: 40px;
    margin-bottom: 20px;
    color: #0e1726;
  }
  
  .privacy-policy-content p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
  }


  @media (max-width: 480px) {

    .privacy-policy-content .container {
      max-width: 85vw;

    }
    .privacy-policy-content h1{
      font-size: 30px;
    }
  
   
  
    .privacy-policy-content h2{
    font-size: 20px;
    margin-bottom: 20px;
  }
  
 
  .privacy-policy-content p{
    font-size: 14px;
    
   
  }
  

  }
  