

.economics-overview-container {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    
  }
  
  .economics-image-container {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
  }
  
  .economics-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Adding a dark overlay */
  .overlay-economics {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  
  .overlay-text-economics {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    width: 100%;
    z-index: 2;
    font-size: 40px;
    font-weight: bold;
  }
  
  .economics-book {
    position: relative;
    background-color: #0e1726;
    padding: 80px 0;
    width: 100%;
    margin: 0 auto;
    color: white;
    text-align: center;
    overflow: hidden;
  }

  .economics-p {
    width: 80%;
    margin: 0 auto;
  }



.economics-book::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #c8d4e6;
    clip-path: polygon(20% 0, 100% 0, 100% 55%, 80% 100%, 0 100%, 0 45%);
       z-index: 1;
  }


  
  .economics-book-content {
    position: relative;
    z-index: 2;
    width: 80%;
    margin: 0 auto;
    color:#0e1726;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  
  .economics-book-content h2 {
    font-family: "Akshar", sans-serif;
    font-weight: 400;
    font-size: 40px;
        margin-bottom: 40px;
  }
  
  .economics-book-content p {
    font-size: 22px;
    margin-bottom: 40px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: 1.5;
  }
  
  .economics-book-content button {
    background-color: #0e1726;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 10%;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 18px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin: 0 auto 0 auto;
  }
  
  .economics-book-content button:hover {
    background-color: #F39C12;
  }
  
  .economics-statement {
    background-color: white;
    width: 70%; /* Full viewport width */
    padding-top: 60px;
    padding-bottom: 60px;
    
    margin: 20px auto 30px auto;
    position: relative;
   
    height: 40vh;
    color:#0e1726;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .economics-final {
    font-size: 25px;
    font-weight: 400;
    margin-bottom:40px;

  }

  .economics-name {
    font-size: 20px;
    word-spacing: 2px;
  }
  
  
  .economics-overview-container .economics-title {
    font-size: 40px;
    margin: 20px 0;
    color:white;
    font-family: "Akshar", sans-serif;
    font-weight: 400;
  }
  
  .economics-overview-container .economics-subtitle {
    font-size: 22px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: 1.5;
    margin: 10px 0 40px;
    color:white;
  }
  
  .economics-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    max-width: 80%;
    align-items: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  
  .economics-tab-button {
    flex: 1;
    padding: 10px 0;
    border: solid white 1px;
    cursor: pointer;
    font-size: 18px;
    background-color: transparent;
    color: white;
    transition: background-color 0.3s, color 0.3s;
    max-width: 450px; /* Adjust as needed */
    margin: 0 10px;
    border-radius: 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
  
  .economics-tab-button:hover {
    background-color: #dbefff; /* Change this to your desired hover color */
    color: #0e1726;
  }
  
  
  .economics-tab-button.active {
    background-color: #F39C12 ;
    color: white;
    border: solid 1px #F39C12;
  }
  
  .economics-tab-content {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    height: 0;
    overflow: hidden;
  }
  
  .economics-tab-content.active {
    opacity: 1;
    height: auto;
    transition: opacity 0.3s ease-in-out, height 0s;
  }
  .economics-timeline {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
  }

  .economics-timeline-12 {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .economics-timeline-item {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
    width: 100%;
    position: relative;
  }
  
  .economics-timeline-item.left {
    justify-content: flex-start;
  }
  
  .economics-timeline-content {
    background-color: #3e4f75; /* Slate Blue */
    padding: 20px;
    border-radius: 10px; /* Rounded corners */
    width: 400px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    position: relative;
    z-index: 1;
    margin-top: 0;
    color: #ffffff; /* Font color for better contrast */
  }
  
  .economics-timeline-content p {
    margin: 0;
    font-weight: 400; /* Normal font weight */
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    line-height: 1.5;
  }
  
  .economics-timeline-content h3 {
    color: #F39C12; /* Peach Orange */
    font-size: 22px;
    margin-bottom: 10px;
    margin-top: 0;
    font-family: "Roboto", sans-serif;
  }
  
  .economics-timeline-item.left .economics-timeline-content::after {
    content: '';
    position: absolute;
    top: 15%;
    right: -15px;
    width: 15px;
    height: 25px;
    background-color: #3e4f75 ;
    clip-path: polygon(0 0, 0 100%, 100% 50%);
    transform: translateY(-50%);
  }
  
  .economics-timeline-item.right .economics-timeline-content::after {
    content: '';
    position: absolute;
    top: 15%;
    left: -14px;
    width: 15px;
    height: 25px;
    background-color: #3e4f75;
    clip-path: polygon(100% 0, 100% 100%, 0 50%);
    transform: translateY(-50%);
  }
  
  .economics-timeline-icon {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%); /* Adjusted to align with the line */
    width: 20px;
    height: 20px;
    background-color: #c6e2f9;
    color: #0e1726;
    font-weight: 700;
    border: 3px solid #0e1726;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 2;
    top: 20px; /* Move this value down to adjust the position */
  }
  .economics-timeline::before {
    content: '';
    position: absolute;
    width: 5px;
    background-color: #e0e0e0;
    top: 50px; /* Move this value down to adjust the position */
    bottom: 150px; /* Adjust this if needed to extend the line further down */
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }

  .economics-timeline-12::before {
    content: '';
    position: absolute;
    width: 5px;
    background-color: #e0e0e0;
    top: 50px; /* Move this value down to adjust the position */
    bottom: 200px; /* Adjust this if needed to extend the line further down */
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }
  
  
  .economics-statement {
    background-color: white;
    width: 70%; /* Full viewport width */
    padding-top: 60px;
    padding-bottom: 60px;
    
    margin: 20px auto 30px auto;
    position: relative;
    font-size: 30px;
    color:#0e1726;
  }
  
  .economics-bg {
    background-color: #0e1726;
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
    
  }
  @media (max-width: 1550px) {
    .economics-book-content button {
      width: 13%;
    }
  }

  @media (max-width: 1024px) {
    .economics-image-container {
      height: 350px;
    }
  
    .economics-book-content button {
      width: 35%;
      font-size: 14px;
    }
  
    .overlay-text-economics {
      font-size: 35px;
    }
  
    .economics-book-content h2 {
      font-size: 30px;
    }
  
    .economics-book-content p {
      font-size: 18px;
    }
  
    .economics-tab-button {
      font-size: 17px;
      width: 80%; /* Adjust for larger width on smaller screens */
    max-width: none;
    }
  
    .economics-timeline-content p {
      font-size: 16px;
    }
  
    .economics-timeline-content h3 {
      font-size: 20px;
    }
  }
  
  @media (max-width: 992px) {
    .economics-image-container {
      height: 300px;
    }
  
    .overlay-text-economics {
      font-size: 32px;
    }
  
    .economics-book-content h2 {
      font-size: 28px;
    }
  
    .economics-book-content p {
      font-size: 17px;
    }
  
    .economics-tab-button {
      
        width: 90%; /* Make buttons even wider */
      
      font-size: 16px;
    }
  
    .economics-timeline-content p {
      font-size: 15px;
    }
  
    .economics-timeline-content h3 {
      font-size: 19px;
    }
  }
  
  @media (max-width: 768px) {
    .economics-image-container {
      height: 250px;
    }
  
    .overlay-text-economics {
      font-size: 28px;
    }
  
    .economics-book-content h2 {
      font-size: 26px;
    }
  
    .economics-book-content p {
      font-size: 16px;
    }
  
  
  
    .economics-timeline-content p {
      font-size: 14px;
    }
  
    .economics-timeline-content h3 {
      font-size: 18px;
    }
  
    .economics-timeline-content {
      width: 350px;
    }
  }
  
  @media (max-width: 480px) {
    .economics-image-container {
      height: 170px;
    }
  
    .economics-book-content button {
      width: 35%;
      font-size: 14px;
    }

    .overlay-text-economics {
      font-size: 18px;
    }
  
    .economics-book::before {
      clip-path: polygon(70% 0, 100% 0, 100% 85%, 30% 100%, 0 100%, 0 15%);
       }

       .economics-book-content {
        width: 100%;
       }

    .economics-book-content h2 {
      font-size: 30px;
      margin-top: 20px;
    }
  
    .economics-book-content p {
      font-size: 16px;
      width: 90%;
    }

    .economics-final {
      font-size: 15px;
     font-weight: 300;
      
    }

 .economics-statement .economics-final {
    width: calc(120%);
    margin: 0px 0px 20px auto;
    transform: translateX(-10%);
 }
 
    .economics-statement {
      margin: 0px auto;
    }

    .economics-name {
      font-size: 16px;
    }
    
    .economics-overview-container .economics-title {
      font-size: 30px;
    }

    .economics-overview-container .economics-subtitle {
      font-size: 16px;
      
    }

    .economics-tab-button {
      font-size: 16px;
      max-width: none !important; /* Remove any max-width restriction */
      width: 100% !important;  /* Make the tabs almost full width */
    
    }
  
    .economics-timeline-content p {
      font-size: 15px;
    }
  
    .economics-timeline-content h3 {
      font-size: 17px;
    }
  
    .economics-timeline-content {
      width: 300px;
    }
  
    .economics-tab-content {
      padding: 0 10px;
      
    }

    .economics-timeline-item {
      justify-content: flex-start;
      flex-direction: row-reverse; /* Align items on the right */
      padding-left: 50px; /* Space for the line on the left */
      padding-right: 0; /* Remove right padding */
    }
  
    .economics-timeline-item.left .economics-timeline-content::after {
      display: none; /* Remove the left arrow */
    }
  
    .economics-timeline-item.right .economics-timeline-content::after {
      display: none; /* Remove the right arrow */
    }
  
    .economics-timeline-icon {
      position: absolute;
      left: 25px; /* Position icon over the line */
      transform: translateY(-50%); /* Center icon vertically */
      top: 50%;
    }
  
    .economics-timeline::before {
      left: 35px; /* Align the line under the icon */
      transform: translateX(0);
      width: 5px; /* Ensure the line width matches the icon */
      bottom: 90px;
      top: 80px;
    }

    .economics-timeline-12::before {
      left: 35px; /* Align the line under the icon */
      transform: translateX(0);
      width: 5px; /* Ensure the line width matches the icon */
      bottom: 110px;
      top: 100px;
    }
  }