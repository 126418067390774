.about-prime {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0; /* Remove any margin that could push content down */
  padding: 0; /* Remove padding to ensure it sits at the top */
}

.image-container-about {
  position: relative;
  width: 100%;
  height: 400px; /* Fixed height for desktop view */
  overflow: hidden;
  margin: 0; /* Remove margin to ensure it starts at the top */
}

.about-image {
  position: absolute;
  top: 100%; /* Aligns the top of the image to the bottom of the container */
  left: 0;
  width: 100%;
  height: auto;
  transform: translateY(-80%); /* Moves the image up */
  object-fit: cover;
}

.overlay-text-about {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  width: 100%;
  font-size: 40px;
  padding: 10px;
  box-sizing: border-box;
}

.about-content {
  width: 65%;
  padding-top: 60px;
  padding-bottom: 75px;
  text-align: center;
  color: #0A1322;
  margin-top: 0; /* Ensure no margin pushes content down */
  height: 70vh;
  display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-content h1, .about-content h2 {
  color: #0e1726;
  margin-bottom: 40px;
  font-family: "Akshar", sans-serif;
font-weight: 400;
font-size: 40px;

}

.about-content p {
  font-size: 22px;
  color: #0A1322;
  line-height: 1.5;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.learn-more-button {
  padding: 10px 20px;
  background-color: #F39C12;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
 
  transition: background-color 0.3s ease;
  max-width: 150px;
  margin: 40px auto 0 auto;
}

.learn-more-button:hover {
  background-color: #cd3232;
}

@media (max-width: 1550px) {
  .about-content {
    width: 65%;
    padding-top: 60px;
    padding-bottom: 75px;
    text-align: center;
    color: #0A1322;
    margin-top: 0; /* Ensure no margin pushes content down */
    height: 80vh;
    display: flex;
      flex-direction: column;
      justify-content: center;
  }
}
/* Responsive styles */
@media (max-width: 1024px) {
  .about-image {
    top: 0; /* Align the top of the image to the container */
    transform: translateY(0); /* No translation, so the image fits perfectly at the top */
    height: 100%; /* Adjust height to ensure the image covers the full container */
    transform: scale(1.5);
  }
}

@media (max-width: 992px) {
  .about-image {
    top: 0; /* Align the top of the image to the container */
    transform: translateY(0); /* No translation, so the image fits perfectly at the top */
    height: 100%; /* Adjust height to ensure the image covers the full container */
  }
}

@media (max-width: 768px) {
  .overlay-text-about h1{
    font-size: 70px;
  }


}

@media (max-width: 480px) {
  .overlay-text-about h1{
    font-size: 36px;
  }

  .image-container-about {
    height: 170px;

  }
  .about-image {
    transform: scale(1.5);
    height: 170px;
    
}

.about-content h2{
  font-size: 30px;
  margin-bottom: 20px;
}

.about-content {
  width: 90%;
  height: 85vh;
  
}
.about-content p{
  font-size: 15px;
  
 
}

.learn-more-button {
  font-size: 14px;
}
}
@media (max-width: 360px) {
  .overlay-text-about h1{
    font-size: 36px;
  }
}