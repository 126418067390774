.company-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box; /* Ensures padding and borders are included in the width */
}

.image-container-profile {
  position: relative;
  width: 100%;
  height: 400px; /* Fixed height */
  overflow: hidden; /* Ensures nothing extends beyond the container size */
}

.profile-image {
  position: absolute;
  top: 100%; /* Push the top of the image to align at the bottom of the container */
  left: 0;
  width: 100%;
  height: auto; /* Allow natural height to maintain aspect ratio */
  transform: translateY(-80%); /* Shift the image back up by its own height */
  object-fit: cover; /* Cover the area without distorting the aspect ratio */
}

.overlay-text-profile {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Align center perfectly */
  color: white; /* White color for visibility */
  text-align: center;
  width: 100%;
  font-size: 40px;
  padding: 10px;
  box-sizing: border-box;
  z-index: 1;
}

.overlayprofile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

/* Full-width sections */
.section {
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in width */
  padding: 30px 20px;
  text-align: center;
  padding-top: 75px; /* Adjusted for better alignment */
  padding-bottom: 100px; /* Adjusted for better alignment */
}

.target-icon {
  width: 70px;
  height: 70px;
  margin-bottom: 40px; /* Adjusted for better alignment */
  fill: #0A1322; /* Set the color of the SVG icon */
}

.vision-icon {
  width: 70px;
  height: 70px;
  margin-bottom: 40px; /* Adjusted for better alignment */
  fill: #0A1322; /* Set the color of the SVG icon */
}

.tutor-icon {
  width: 70px;
  height: 70px;
  margin-bottom: 40px; /* Adjusted for better alignment */
  fill: #0A1322; /* Set the color of the SVG icon */
}


.mission-section {
  background-color: #dceefc;
  color: #0A1322;
}


.vision-section {
  background-color: #c6e2f9;
  color: #0A1322;
}

.tutor-section {
  background-color: #b6d9f5;
  color: #0A1322;
}

.profile-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.profile-content h2 {
  font-size: 50px;
  font-weight: bold;
  margin-top: 10px; /* Adjusted for better alignment */
  
}

.profile-content h3 {
  font-size: 40px;
  margin-top: 10px;
  font-family: "Akshar", sans-serif;
font-weight: 400;
}

.profile-content p {
  font-size: 22px;
  margin-top: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.5;
}

.thin {
  font-weight: 400; /* Thin font weight */
}


@media (max-width: 1024px) {
  .profile-image {
    top: 0; /* Align the top of the image to the container */
    transform: translateY(0); /* No translation, so the image fits perfectly at the top */
    height: 100%; /* Adjust height to ensure the image covers the full container */
    transform: scale(1.5);
  }
}

@media (max-width: 992px) {
  .profile-image {
    top: 0; /* Align the top of the image to the container */
    transform: translateY(0); /* No translation, so the image fits perfectly at the top */
    height: 100%; /* Adjust height to ensure the image covers the full container */
  }
}

@media (max-width: 768px) {
  .overlay-text-profile h1{
    font-size: 70px;
  }


}

@media (max-width: 480px) {
  .overlay-text-profile h1{
    font-size: 36px;
  }

  .image-container-profile{
    height: 200px;

  }
  .profile-image {
    transform: scale(1.5);
    height: 200px;
    
}

.profile-content h2 {
  font-size: 40px;
  font-weight: bold;
  margin-top: 10px; /* Adjusted for better alignment */
}

.profile-content h3 {
  font-size: 25px;
  margin-top: 10px;
}

.profile-content p {
  font-size: 15px;
  margin-top: 20px;
  width: 100%;
}

}
@media (max-width: 360px) {
  .overlay-text-profile h1{
    font-size: 36px;
  }
}