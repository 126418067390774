/* Container for FAQ section */
.faqs-page {
    width: 100%;
    margin: 0 auto;
}

/* Image Container Styling */
.image-container-faq {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
}

.faq-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Adding a dark overlay */
.overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
}

.overlay-text-faq {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    width: 100%;
    z-index: 2;
    font-size: 40px;
    font-weight: bold;
}

/* FAQ List Styling */
.faq-list {
    margin: 40px auto 100px auto;
    max-width: 100%;
    overflow: hidden;

}

/* FAQ Item Styling */
.faq-item {
    background: white;
    color: #0e1726;
    padding: 10px 10px 70px 10px; /* Updated padding to add space at the bottom */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s, max-height 0.7s ease-in-out, transform 0.3s;
    max-height: 100px;
    overflow: hidden;
    display: block;
    align-items: center;
    border-bottom: 2px solid #576569;
    height: auto; /* Allows height to adjust based on content */
    min-height: 60px; /* Minimum height to accommodate content properly */
}

.faq-item.open {
    background-color: #0e1726;
    color: white;
    max-height: 2000px;
    transform: scale(1.02);
    padding-bottom: 10px;
    /* Slightly enlarge when opened */
}

.faq-content {
    width: 80%;
    margin: 0 auto;
    padding: 20px;

}

.faq-item h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    color: #0e1726;
    /* font-size: 25px; */
    padding: 10px 10px; /* Consistent padding around the text */
    line-height: 1.5; /* Adjusted line height for better spacing */
    width: 100%; /* Ensure the border spans the entire width */
    font-family: "Akshar", sans-serif;
font-weight: 400;
font-size: 32px;
}

.faq-item.open h2 {
    color: white;

}

.faq-item.open p {
    margin-top: 10px;
    margin-bottom: 12px;
    color: white;
    font-size: 22px;
    font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  margin-left: 10px;
}

/* Dropdown arrow styling */
.dropdown-arrow {
    transition: transform 0.3s;
}

.faq-item.open .dropdown-arrow {
    transform: rotate(180deg);
}


@media (max-width: 768px) {
    .overlay-text-faq h1{
      font-size: 70px;
    }

    .faq-content {
        padding: 15px;
        width: 95%;
    }
  }


@media (max-width: 480px) {

    .image-container-faq {
        height: 170px;
    }

    .overlay-text-faq h1{
        font-size: 36px;
    }

    .faq-list {
        margin-top: 10px;
    }

    .faq-item {
        padding: 0;
    }

    .faq-content {
        padding: 15px;
        width: 95%;
    }

   

    .faq-item.open h2 {
        font-size: 22px;

    }

    .faq-item.open p {
        font-size: 15px;
    }

    .faq-item h2 {
        font-size: 22px; /* Even smaller font size for very small screens */
        padding: 12px 10px; /* Adjust padding to maintain spacing */
        line-height: 1.3; /* Fine-tune line height for visual appeal */
    }
}

@media (max-width: 360px) {
    .overlay-text-faq h1{
      font-size: 36px;
    }
  }