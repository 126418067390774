
.contact-container {
    margin-top: -50px; /* Adjust this value as needed */
    background-size: cover;
    background-position: center;
    height: 120vh;
    position: relative;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

  .contact-header {
    text-align: center;
   margin-top: 40px;
    margin-bottom: 20px;
    z-index: 3;
  }

  .contact-header h1 {
    color: #F39C12;
    font-size: 60px;
    margin-bottom: 0px;
    font-family: "Akshar", sans-serif;
    font-weight: 400;
  }

  .contact-header p{
    font-size: 22px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: 1.5;
  }

  .contactus-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1; 
  }
  .contact-details {
    width: 80%;
    max-width: 1000px;
    background-color: rgba(11, 24, 46, 0.9);
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    z-index: 2;
    height: 70vh;
  }
  
  .contact-method {
    margin: 50px 0;
  }
  

  
  .contact-method h3 {
    color: #ffffff;
    margin-bottom: 1px;
    font-size: 30px;
    font-family: "Akshar", sans-serif;
font-weight: 400;
  }
  
  .contact-method p {
  
    color: #ffffff;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: 1.5;
  }
  
  .social-media-links {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }


  
  .social-media-links a {
    font-size: 26px;
    color: #ffffff; /* Default color */
    margin: 0 20px;
    transition: color 0.2s ease; /* Smooth transition for color change */
  }
  
  .social-media-links a:hover {
    color: #F39C12; /* New color on hover */
  }
  
  .company-info {
    margin-top: 40px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
    font-size: 16px;
    color: #cdf3ff;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: 1.5;
  }

  @media (max-width: 1550px) {
.contact-details {
  height: auto;
}
  }
  
  @media (max-width: 992px) {
    .contact-details {
        flex-direction: column; /* Stack contact methods vertically */
        align-items: center;
    }

    .contact-method {
        width: 80%; /* Full width for better readability */
    }
}

@media (max-width: 768px) {

  .contact-container {
    padding: 20px 10px;
    margin-top: -80px;
}
    .contact-header h1 {
        font-size: 36px; /* Smaller font size for smaller screens */
        
    }

    .contact-header p {
        font-size: 16px;
    }

    .contact-method h3 {
        font-size: 22px;
    }

    .contact-method p {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .contact-container {
        padding: 20px 10px;
        margin-top: 0;
        height: 110vh;
    }

    .contact-details {
      width: 90%;
      height: 70vh;
    }

    .contact-method {
        width: 100%; /* Use full width for each contact method */
        margin-bottom: 30px;
        margin-top: 0px;
    }

    .contact-method p{
      margin-top: 5px;
    }
.contact-header h1{
font-size: 35px;
font-weight: 500;
}

.contact-header p{
  font-size: 16px;
  font-weight: 400;
  }

    .social-media-links a {
        font-size: 20px; /* Slightly smaller icons */
    }
}

@media (max-width: 360px) {
    .contact-header h1 {
        font-size: 24px;
    }

    .contact-header p,
    .contact-method p,
    .contact-method h3 {
        font-size: 12px; /* Ensure text is not too bulky on very small screens */
    }
}